import { subscribeFormValidation } from "./subscribe";
import { initNav } from "../../common/js/navbar";
import { gdpr, cookieCheck } from "../../common/js/gdpr";
import 'lazysizes';
import "../../common/js/pollyfill";
import "bootstrap";
import { initYoutubeEmbed } from "../../common/js/utils/lazy/youtube-embed";

$.fn.dropdown.Constructor.Default.popperConfig = {positionFixed: true};

export const initCore = (addClass = true, subForm = true) => {
    initNav(addClass);

    if (subForm) {
        subscribeFormValidation();
    }

    if (document.querySelectorAll(".video-container .image-container[data-id]").length > 0) {
        document.querySelectorAll(".video-container .image-container[data-id]").forEach(e => initYoutubeEmbed(e));
    }

    cookieCheck();
};